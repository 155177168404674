.app__notes-delete_table-loading {
    border: 1px solid #ccc;
    width: 97%;
    margin-bottom: 20px;
    overflow-x: auto;
}

.app__notes-delete_table-loading th:nth-child(1) {
    width: 5%;
    height: 25px;
}
.app__notes-delete_table-loading th:nth-child(2) {
    width: 10%;
}
.app__notes-delete_table-loading th:nth-child(3) {
    width: 10%;
}
.app__notes-delete_table-loading th:nth-child(4) {
    width: 10%;
}


.app__notes-delete_table-loading tbody tr td {
    margin-top: 5px;
}