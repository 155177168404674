.app__register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100vw;

}

.app__register-wrapper {
    margin: 6rem 0 3rem 0;
    min-height: 50%;
    min-width: 50%;
    background-color: var(--color-back);
}

.app__register-wrapper h2 {
    color: white;
    font-size: 30px;
    letter-spacing: 2px;
    margin: 1rem 0 1rem 0;
}

.app__register-wrapper input {
    width: 80%
}

.app__register-email,
#email,
#password {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem 0 1rem 0;
}


.app__register-admemail,
#admemail,
#admpassword {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem 0 1rem 0;
}


.app__register-admemail label {
    color: white;
    margin-top: 0.5rem;
}

.app__register-email label {
    color: white;
    margin-top: 0.5rem;
}

.app__register-form button {
    margin-bottom: 2rem;
}