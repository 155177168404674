.app__login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100dvh;
    width: 100vw;
}

.app__login-wrapper {
    margin: 6rem 0 3rem 0;
    min-height: 50%;
    min-width: 50%;
    background-color: var(--color-dark-nav-footer);
    border: 1.5px solid var(--color-dark-border);
    border-radius: 10px;
}

.app__login-wrapper h2 {
    color: white;
    font-size: 30px;
    letter-spacing: 2px;
    margin: 1rem 0 1rem 0;
}

.app__login-wrapper input {
    width: 80%
}

.app__login-email,
.app__login-password {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2rem 0 2rem 0;
}


.app__login-email label {
    color: white;
    margin-bottom: 1rem;
}

.app__login-password label {
    color: white;
    margin-bottom: 1rem;
}

.app__login-form button {
    margin-bottom: 2rem;
}




@media screen and (max-width: 1200px) {
    .app__login-wrapper {
        min-width: 60%;
    }
}

@media screen and (max-width: 768px) {
    .app__login-wrapper {
        margin: 6rem 0 3rem 0;
        min-height: 50%;
        min-width: 75%;
    }
}

@media screen and (max-width: 450px) {
    .app__login-wrapper {
        min-height: 50%;
        min-width: 90%;
    }

    .app__login-wrapper h2 {
        font-size: 24px;
    }

    .app__login-wrapper input {
        width: 85%
    }
}

@media screen and (max-width: 410px) {}

@-moz-document url-prefix() {
    @media screen and (max-width: 1200px) {
        .app__login-container {
            height: 100%;
        }
        .app__login-wrapper {
            margin: 8rem 0 0rem 0;
        }
    }
}