.app__header-wrapper {
  padding-top: 120px;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-family: var(--font-base);
}

.app__header-wrapper p {
  font-size: 1.5rem;
}


.app__header-et h2{
  font-size: 32px;
  color: var(--color-dark-textwhite);
}

.app__header-et button, .app__header-set button {
  width: 50vw;
  margin: 10px 10px 10px 10px;
  padding: 12px 30px 12px 30px;
  background-color: var(--color-gray);
  border: 2px solid var(--color-dark-border);
  color: var(--color-dark-textwhite);
  border-radius: 15px;
  font-weight: 500;
  letter-spacing: 0.08em;
  line-height: 30px;
  font-size: 16px;
  cursor: pointer;
}

.app__header-et button:hover {
  box-shadow: 0 15px 15px 0px rgba(0, 111, 171, 0.2)
}

.app__header-et .et p {
  transition: padding .4s;
}

.app__header-et button:hover p {
  padding-left: 20px;
}

.app__header-et button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.app__header-et span {
  padding-left: 10px;
}

.app__header-et span .arrow{
  border-radius: 50%;
  color: var(--color-dark-background);
  border: 2px solid var(--color-dark-background);
}

.app__header-et span svg {
  color: var(--color-dark-textwhite);
}

.set-title {
  font-size: 32px;
  color: var(--color-dark-textwhite);
  margin-top: 25px;
}

.app__header-set {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.app__header-set button {
  width: 35vw;
}

.app__header-set span {
  padding-left: 10px;
}


.app__header-set span .arrow{
  border-radius: 50%;
  color: var(--color-dark-background);
  border: 2px solid var(--color-dark-background);
}

.app__header-set span svg {
  color: var(--color-dark-textwhite);
}

.app__header-set button:hover {
  box-shadow: 0 15px 15px 0px rgba(40, 42, 58, 0.5)
}

.app__header-set .set p {
  transition: padding .4s;
}

.app__header-set button:hover p {
  padding-left: 20px;
}

.app__header-set button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}



@media screen and (max-width: 1150px) {
.app__header-set {
  flex-direction: column;
}
.app__header-set button {
  width: 50vw;
}
}

@media screen and (max-width: 1000px) {
  .app__header-et button, .app__header-set button {
    width: 75vw;
  }
}

@media screen and (max-width: 650px) {
  .app__header-et button, .app__header-set button {
    width: 85vw;
  letter-spacing: 0.04em;
  line-height: 26px;
  font-size: 10px;
  }
}

@media screen and (max-width: 410px) {
  .app__header-et button, .app__header-set button {
    width: 90%;
  }
}

@media screen and (max-width: 385px) {
  .app__header-et button, .app__header-set button {
    letter-spacing: 0.02em;
  }
  .app__header-wrapper p {
  font-size: 1.3rem;
  }
}

@media screen and (max-width: 330px) {
.app__header-wrapper p {
  font-size: 1.1rem;
}
}