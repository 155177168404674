@tailwind base;
@tailwind components;
@tailwind utilities;
@import-normalize;

@import url('https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

:root {

  /*Night/Dark Mode*/

  --color-dark-background: #18181b;
  --color-dark-border: #006fab;
  --color-blue: #0a4a70;
  --color-dark-nav-footer: #072f4a;
  --color-dark-textwhite: #fff;
  --color-dark-textwhitehover: #b9e6fe;
  /*Button*/
  --color-dark-button-inside: #18181b;
  --color-dark-button-border: #7bd4fe;
  --color-dark-button-text: #7bd4fe;
  --color-dark-buttonhover-inside: #055a87;
  --color-dark-buttonhover-border: #7bd4fe;
  --color-dark-buttonhover-text: #7bd4fe;
  --color-dark-btndisabled: #6d6d6d;
  --color-dark-btndisabledtext: #282A3A;
  /*Button*/
  --color-enersa-orginal: #006eab;
  /*Night/Dark Mode*/


  --font-base: 'Roboto', serif;
  --font-alt: 'Open Sans', sans-serif;

  --color-gray: #282A3A;
  --color-cooper: #735F32;
  --color-golden: #C69749;
  --color-white: #FFF;

}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

a {
  color: unset;
  text-decoration: none;
}

body {
  margin: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media screen and (max-width: 450px) {
  .react-confirm-alert-body {
    width: 350px;
  }
}


@media screen and (max-width: 375px) {
  .react-confirm-alert-body {
    width: 275px;
  }
}