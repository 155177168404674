.app__register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100dvh;
    height: 100%;
    width: 100vw;
}

.app__register-wrapper {
    margin: 6rem 0 3rem 0;
    min-height: 50%;
    min-width: 50%;
    background-color: var(--color-dark-nav-footer);
}

.app__register-wrapper h2 {
    color: white;
    font-size: 30px;
    letter-spacing: 2px;
    margin: 1rem 0 1rem 0;
}

.app__register-wrapper input {
    width: 80%
}

.app__register-email,
#email,
#password,
#verificationPassword,
#newPassword {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem 0 1rem 0;
}


.app__register-admemail label {
    color: white;
    margin-top: 0.5rem;
}

.app__register-email label {
    color: white;
    margin-top: 0.5rem;
}

.app__register-form button {
    margin-bottom: 2rem;
}

.password-validation {
    color: red;
    margin: 0;
}



@media screen and (max-width: 1200px) {
    .app__register-wrapper {
        min-height: 75%;
        min-width: 75%;
    }
}

@media screen and (max-width: 768px) {
    .app__register-wrapper {
        min-height: 80%;
        min-width: 80%;
        margin: -6rem 0 0rem 0;
    }

    .app__register-wrapper input {
        width: 85%
    }
}

@media screen and (max-width: 650px) {}

@media screen and (max-width: 410px) {
    .app__register-wrapper {
        min-height: 95%;
        min-width: 95%;
    }

    .app__register-wrapper h2 {
        font-size: 20px;
        margin: 1rem 0 0.5rem 0;
    }

    .app__register-wrapper input {
        width: 90%
    }
}