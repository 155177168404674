.app__notes-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100vw;
}

.app__notes-wrapper {
    margin: 6rem 0 3rem 0;
    min-height: 80vh;
    min-width: 95vw;
}

.app__notes-read {
    width: 90vw;
    background-color: var(--color-gray);
    border: 1.5px solid var(--color-dark-border);
    border-radius: 10px;
    margin-top: 10px;
}

.app__notes-read h1 {
    text-transform: capitalize;
    padding: 1rem 1rem;
    font-size: 1.75rem;
}

.app__notes-note {
    padding: 0.5rem 0 0.5rem 1rem;
    text-align: left;
    border: 3px solid var(--color-dark-nav-footer);
    border: 1.5px solid var(--color-dark-border);
}

.app__notes-note p:first-child {
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 3px;
    font-size: 1.25rem;
}

.app__notes-note p:nth-last-child(-n+3) {
    font-size: 0.75rem;
}

.app__notes-write {
    background-color: var(--color-gray);
    border: 1.5px solid var(--color-dark-border);
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 10px;
}

.app__notes-write_title {
    margin: 2rem 0 0.5rem 1rem;
    width: 60%;
    resize: none;
}

.app__notes-write_note {
    margin: 0rem 0 0.5rem 1rem;
    width: 70%;
    resize: none;
}

.app__notes-write h2 {
    margin: 2rem 0 0rem 2rem;
    font-size: 1.75rem;
}

.app__notes-write button {
    margin: 1rem 0 3rem 2rem;
}

.app__notes-delete {
    background-color: var(--color-gray);
    border: 1.5px solid var(--color-dark-border);
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.app__notes-delete_table {
    border: 1px solid #ccc;
    width: 97%;
    margin-bottom: 20px;
    overflow-x: auto;
    /* Para permitir el desplazamiento horizontal en pantallas pequeñas */
}

.app__notes-delete_table thead {
    display: table-header-group;
    /* Establece la fila de encabezado como grupo de tabla para mantenerla visible en pantallas pequeñas */
}

.app__notes-delete_table tr {
    border: 1px solid #ccc;
}

.app__notes-delete h1 {
    margin: 1rem 0 0.5rem 0rem;
    resize: none;
    text-transform: capitalize;
    text-align: center;
}


.app__notes-delete_note p {
    margin: 2px 10px 2px 10px;
}

.app__notes-delete_note .author {
    margin-left: 200px;
}

.app__notes-delete_note .author,
.app__notes-delete_note .date {
    font-size: 12px;
}


.app__notes-delete-768 {
    padding: 0.5rem 0 0.5rem 1rem;
    text-align: left;
    width: 95%;
    padding-bottom: 20px;

}

.app__notes-delete-768 p:first-child {
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 3px;
    font-size: 1.25rem;
}

.app__notes-delete-768 p:nth-last-child(-n+3) {
    font-size: 0.75rem;
}

.app__notes-delete-note {
    border: 1.5px solid var(--color-dark-border);
    padding: 2px;
}

.spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(80, 80, 80, 0.555) !important;
}


@media screen and (max-width: 768px) {

    
.app__notes-read {
    width: 100vw;

}

    .app__notes-write {
        align-items: center;
    }

    .app__notes-write_title {
        width: 90%;
        margin: 1.5rem 0 0.5rem 0rem;
    }

    .app__notes-write_note {
        width: 90%;
        margin: 0.5rem 0 0rem 0rem;
    }

    .app__notes-write h2 {
        margin: 2rem 0 0rem 0rem;
        font-size: 1.75rem;
    }

    .app__notes-write button {
        margin: 1.5rem 0 1.5rem 0rem;
    }
}