.app__warning-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100dvh;
    width: 100vw;
}

.app__warning-wrapper {
    display: flex;
    flex-direction: column;
    margin: 6rem 0 3rem 0;
    min-height: 50%;
    min-width: 50%;
    background-color: var(--color-black);
}

.app__warning-wrapper h2 {
    color: white;
    font-size: 30px;
    letter-spacing: 2px;
    margin: 1rem 0 1rem 0;
}



@media screen and (max-width: 1200px) {}

@media screen and (max-width: 768px) {
    .app__warning-wrapper {
        margin: 0rem 0 3rem 0;
        min-height: 75%;
        min-width: 75%;
    }
}

@media screen and (max-width: 650px) {}

@media screen and (max-width: 410px) {
    .app__warning-wrapper {
        margin: 0rem 0 3rem 0;
        min-height: 90%;
        min-width: 90%;
    }

    .app__warning-wrapper h2 {
        font-size: 24px;
    }
}